<template>
  <div class="flex-auto md:flex ml-3">
    <dropdown ref="dropdown" size="lg" :has-arrow="true" :menu-padding="0">
      <template v-slot:button>
        <div
          class="text-left text-14px text-oCharcoal"
          style="min-width: 106px"
        >
          <span class="text-oCharcoal text-md mx-2"
            ><i class="fas fa-filter"></i
          ></span>
          <span class="font-bold mx-2">Filter</span>
          <span
            v-if="activeFilterCount"
            class="rounded-full bg-blue-700 text-white inline-block text-center"
            style="height:17px; width: 17px; font-size:13px; line-height:17px"
            >{{ activeFilterCount }}</span
          >
        </div>
      </template>
      <template v-slot:menu>
        <div class="filterGroupsContainer md:flex md:flex-wrap md:p-2">
          <slot></slot>
        </div>

        <div class="text-right p-2" style="white-space:nowrap">
          <button
            @click="applyFilter"
            class="px-3 py-1 bg-green-500 mr-1 text-white rounded"
          >
            Apply Filter
          </button>
          <button @click="clearFilter" class="px-3 py-1 bg-gray-200 rounded">
            Clear
          </button>
        </div>
      </template>
    </dropdown>
    <slot name="pills"></slot>
  </div>
</template>
<script>
import Dropdown from './Dropdown'

export default {
  components: {
    Dropdown,
  },
  props: {
    maxFilterPillCount: {
      type: Number,
      default: 0,
    },
    activeFilterCount: {
      required: false,
      default: 0,
    },
    filterOption: {
      type: Object,
    },
  },
  mounted() {
    document.addEventListener('click', this.autoCloseEventListener)
  },
  data() {
    return {
      open: false,
    }
  },

  methods: {
    _close() {
      this.$refs.dropdown._close()
    },
    applyFilter() {
      this.$emit('filterData')
      this._close()
    },
    clearFilter() {
      this.$emit('clear-filter')
      this._close()
    },
  },
  watch: {
    activeFilterCount: {
      handler() {
        //console.log(val);
      },
    },
  },
}
</script>
<style scoped>
.filterGroupsContainer {
  min-width: 241px;
}
@media screen and (min-width: 640px) {
  .filterGroupsContainer {
    width: auto;
  }
}
</style>
